<template>
  <img
    :src="mutableSrc"
    :srcset="`${mutableSrcset} 2x`"
    class="base-photo"
    :class="`base-photo--${view}`"
    :alt="alt"
    :width="width"
    :height="height"
  />
</template>

<script>
import NoPhoto from "@/assets/shared/no-photo.svg";
// utils
import { Buffer } from "buffer";

export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "fluid",
    },
    width: {
      type: [String, Number],
      default: "0",
    },
    height: {
      type: [String, Number],
      default: "0",
    },
    gravity: {
      type: String,
      default: "sm",
    },
    enlarge: {
      type: String,
      default: "no",
    },
    resizingType: {
      type: String,
      default: "auto",
    },
    alt: {
      type: String,
      default: "",
    },
  },
  computed: {
    urlBase64() {
      if (!this.url) return null;

      const buff = Buffer.from(encodeURI(this.url));
      return buff.toString("base64");
    },
    mutableSrc() {
      if (!this.url) return NoPhoto;

      return `${process.env.VUE_APP_IMAGE_URL}/${this.resizingType}/${this.width}/${this.height}/${this.gravity}/${this.enlarge}/${this.urlBase64}`;
    },
    mutableSrcset() {
      if (!this.url) return NoPhoto;

      const width = parseInt(this.width) * 2;
      const height = parseInt(this.height) * 2;

      return `${process.env.VUE_APP_IMAGE_URL}/${this.resizingType}/${width}/${height}/${this.gravity}/${this.enlarge}/${this.urlBase64}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-photo {
  &--fluid {
    @include img-fluid;
  }
}
</style>
