import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import "./styles/main.scss";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import vuescroll from "vuescroll";
import VueMeta from "vue-meta";
import VueAPI from "@/plugins/api/";
import VueDadata from "@/plugins/dadata/";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import VModal from "vue-js-modal";
import Multiselect from "vue-multiselect";

import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { default as defaultSentryOptions } from "@/plugins/sentry";

Vue.use(VueToast, {
  position: "top-right",
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueAPI);

Vue.use(VueDadata, {
  token: "64c868ce433471ea0f1e570be74accf41ed275ab",
});

Vue.use(vuescroll, {
  ops: {
    bar: {
      background: "#00c0a7",
      size: "6px",
    },
  },
});

Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VModal);
Vue.use(VueSplide);

Vue.component("Multiselect", Multiselect);
Vue.component("DatePicker", DatePicker);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    ...defaultSentryOptions,
    Vue,
    dsn: "https://0f523d084ae649b28bb1db2146e5b000@o570435.ingest.sentry.io/5722865",
    integrations: [new BrowserTracing()],
    logErrors: true,
  });
  Sentry.setUser({
    id: store.state.account.id,
    profile: store.state.account.profile,
  });
}

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
